.event-details {
  display: flex;
  align-items: flex-start;
  justify-content: center;
}
.event-details-container {
  width: 80%;
  border-radius: 10px;
  padding: 25px 0px;
}
.event-details-contant {
  max-width: 50%;
  margin: 0 auto;
}
.logo-img {
  margin: 0 auto;
  max-width: 100%;
}
.svg-logo {
  fill: #f4eddc;
}
@media only screen and (max-width: 900px) {
  .event-details-contant {
    max-width: 65%;
  }
}
@media only screen and (max-width: 650px) {
  .event-details-contant {
    max-width: 80%;
  }
}
@media only screen and (max-width: 480px) {
  .event-details-contant {
    max-width: 90%;
  }
}
@media only screen and (max-width: 350px) {
  .event-details-contant {
    max-width: 100%;
  }
}
