.App {
  text-align: center;
  height: 100%;
  min-height: 100vh;
  padding-bottom: 4.625rem;
}

.black-backgroud {
  height: 100%;
  background-color: black;
  opacity: 0.9;
  min-height: 100vh;
}
.grid-layout {
  display: grid;
  grid-auto-columns: 1fr;
  grid-template-columns: 0.4fr 1.6fr;
  grid-template-rows: 1fr;
  gap: 10px 30px;
  grid-template-areas:
    '. .'
    'screen btns';
  padding-right: 3%;
  padding-left: 3%;
}
.cart-icon-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
  cursor: pointer;
  max-width: 300px;
  padding-right: 3%;
  padding-left: 3%;
}
.count-container {
  font-size: 18px;
  font-weight: 800;
  color: #f5efdf;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row-reverse;
}
.screen-layout {
  align-self: stretch;
}
.top {
  position: fixed;
  right: 2rem;
  bottom: 5.5rem;
  border-radius: 100%;
  padding: 0.5rem;
  border: none;
  cursor: pointer;
  opacity: 100%;
  transition: opacity 0.5s;
  background: #f5efdf;
  border-radius: 100px;
  color: black;
  white-space: nowrap;
}
.admin-btns-container {
  display: flex;
  min-width: 12.5rem;
  justify-content: space-around;
  align-items: center;
}
button {
  background-color: black;
  color: #f5efdf;
  border: 0;
  font-size: 18px;
  font-weight: 700;
  border: #f5efdf solid 1px;
  border-radius: 15px;
  margin-bottom: 15px;
  cursor: pointer;
  padding: 10px;
}

@media only screen and (max-width: 1100px) {
  .grid-layout {
    grid-template-rows: auto auto;
    grid-template-areas:
      'btns btns'
      'screen screen';
  }
  .screen-layout {
    grid-area: screen;
    align-self: stretch;
    margin-bottom: 50px;
    margin-top: 3rem;
  }
  .nav-bar {
    color: #f5efdf;
    direction: ltr;
    grid-area: btns;
    overflow: auto;
    white-space: nowrap;
    max-width: 90%;
    padding-inline-start: 0;
    list-style-type: none;
    display: flex;
    align-items: center;
  }
}
@media only screen and (max-width: 1100px) {
  .nav-bar {
    max-width: 100%;
    direction: rtl;
  }
}

@media only screen and (max-width: 600px) {
  @supports (-webkit-touch-callout: none) {
    /* CSS specific to iOS devices */
    .grid-layout-event-list {
      grid-template-rows: auto auto;
      overflow-x: hidden;
    }
  }
}
