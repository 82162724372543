.backgroud-modal {
  height: 100vh;
  background: #76746d7a;
  width: 100vw;
  position: fixed;
  top: 0px;
}
.modal {
  z-index: 9999;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  min-height: 700px;
  background-color: black;
  border-radius: 15px;
  opacity: 0.95;
  display: grid;
  grid-auto-columns: 1fr;
  grid-auto-rows: 1fr;
  grid-template-columns: 1fr;
  grid-template-rows: 0.1fr 1.9fr;
  grid-template-areas:
    'exit-btn'
    'modal-contant';
  border: 3px solid #f4eddc;
  color: #f5efdf;
  min-width: 60%;
}
.modal-contant {
  width: 100%;
  justify-self: center;
  grid-area: modal-contant;
}
.exit-btn {
  justify-self: end;
  align-self: start;
  grid-area: exit-btn;
  padding: 10px;
}
.exit-btn button {
  background-color: transparent;
  color: #f5efdf;
  border: 0;
  font-size: 1.5rem;
  font-weight: 700;
  border: black solid 1px;
  border-radius: 15px;
  margin-bottom: 15px;
  cursor: pointer;
}
.selected {
  grid-gap: 10px 30px;
  display: grid;
  gap: 10px 30px;
  grid-auto-columns: 1fr;
  grid-auto-flow: row;
  grid-template-areas:
    'image-selected'
    'category-name'
    'description-seleted'
    'add-btn';
  grid-template-columns: 1fr;
  margin: 20px auto;
  margin-top: 0;
  min-height: 100%;
  max-width: 100%;
  padding: 20px;
  padding-top: 0;
  grid-template-rows: min-content auto;
}

.image-selected {
  align-self: stretch;
  grid-area: image-selected;
  max-height: 20.5rem;
  min-width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row-reverse;
  justify-self: stretch;
}
.image-selected img {
  max-width: 25rem;
}
.img-selected {
  border-radius: 25px;
  max-height: 100%;
  object-fit: contain;
}

.slider .animated {
  transform: unset !important;
}
.description-seleted {
  justify-self: start;
  align-self: start;
  grid-area: description-seleted;
  min-width: 100%;
  font-size: 1.25rem;
  padding: 1rem;
}

.category-name {
  justify-self: stretch;
  align-self: start;
  grid-area: category-name;
  font-weight: 700;
  font-size: 1.875rem;
}

.add-btn {
  justify-self: center;
  align-self: center;
  font-size: 1.375rem;
}
#comments-selected-input {
  width: min-content;
  height: min-content;
  margin-top: 20px;
}

.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
}
.disable-sclect {
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}
.swiper-slide img {
  display: unset;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.swiper-pagination-bullet-active {
  background-color: #f5efdf !important;
}
.swiper-button-prev {
  cursor: pointer;
  margin-left: 1rem;
}
.swiper-button-next {
  cursor: pointer;
  margin-right: 1rem;
}
@media only screen and (max-width: 950px) {
  .modal {
    min-width: 85%;
  }
  .swiper-button-prev {
    display: none;
  }
  .swiper-button-next {
    display: none;
  }
}

@media only screen and (max-width: 650px) {
  .modal {
    min-width: 80%;
  }
  #comments-selected-input {
    width: 200px;
  }
}
@media only screen and (max-width: 570px) {
  .image-selected img {
    max-width: 20rem;
  }
}
@media only screen and (max-width: 400px) {
  .img-selected {
    max-height: 85%;
  }
}
@media only screen and (max-width: 380px) {
  #comments-selected-input {
    width: 100px;
  }
}
