@font-face {
  font-family: 'Fredoka';
  src: url('assets/fonts/Fredoka/Fredoka-VariableFont_wdth\,wght.ttf');
}
html {
  direction: rtl;
  min-height: 100vh;
  font-family: 'Fredoka';
  font-size: 16px;
}
h1,
h2,
h3,
h4,
h5,
h6,
div,
input,
textarea,
label,
select,
textarea,
button,
p {
  direction: rtl;
  font-family: 'Fredoka';
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  min-height: 100vh;
  font-family: 'Fredoka';
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
div {
  box-sizing: border-box;
}

@media only screen and (max-width: 900px) {
  html {
    font-size: 14px;
  }
}
@media only screen and (max-width: 550px) {
  html {
    font-size: 12px;
  }
}
@media only screen and (max-width: 350px) {
  html {
    font-size: 11px;
  }
}
