.event-list-mobile {
  color: #f5efdf;
}
.grid-inputs {
  max-width: 600px;
  display: grid;
  grid-auto-columns: 1fr;
  grid-auto-rows: 0.5fr;
  grid-template-columns: 1.2fr 0.8fr;
  grid-template-rows: auto;
  gap: 15px 15px;
  grid-template-areas: 'input-area label-input';
  margin: 0 auto;
}
.label-input {
  justify-self: end;
  align-self: center;
  font-size: 20px;
}
.input-area {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 10px;
  border-radius: 20px;
  font-size: medium;
  justify-self: stretch;
  align-self: center;
  font-size: 20px;
  text-align: center;
}
.input-area textarea {
  width: 100%;
  max-height: 100%;
}
.input-area :first-child {
  margin-right: 10px;
}
.input-area :last-child {
  margin-left: 10px;
}
.sign-btn {
  color: #f5efdf;
  background: transparent;
  border: 1px solid;
  font-size: 20px;
  cursor: pointer;
  border-radius: 20px;
  padding: 10px;
  margin-bottom: 2.2rem;
}
.btns-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 50px;
  flex-wrap: wrap;
}

.btns-container :last-child {
  margin-left: 1.1rem;
}
.btns-container :first-child {
  margin-right: 1.1rem;
}

@media only screen and (max-width: 600px) {
  .grid-inputs {
    min-height: 100%;
    grid-template-columns: unset;
    grid-auto-rows: min-content;
    grid-auto-columns: 262px;
    grid-template-areas:
      'input-area'
      'label-input';
  }
  .label-input {
    justify-self: center;
  }
  .input-area {
    min-width: 100%;
    border-radius: 20px;
    align-self: stretch;
    font-size: larger;
    font-weight: 700;
    margin: 0 auto;
    max-width: 200px;
    min-height: 100px;
    font-size: 15px;
  }
  .label-input-sign {
    margin-top: 35px;
    flex-direction: column;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .sign-canvas {
    margin-top: 35px;
    min-height: 100%;
    max-width: unset;
  }
  .event-list-mobile {
    color: #f5efdf;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  .sign-btn {
    color: #f5efdf;
    background: transparent;
    border: 1px solid;
    font-size: 15px;
    cursor: pointer;
    border-radius: 20px;
    padding: 8px;
    margin-bottom: 5px;
  }
  .btns-actions-area {
    margin-top: 50px;
    margin-bottom: 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .select-area {
    background-color: black;
    color: #f5efdf;
  }
}
.select-area {
  border-radius: 20px;
}
