.nav-link {
  min-width: 50%;
  margin-bottom: 20px;
  min-height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  color: #f5efdf;
  cursor: pointer;
  font-size: large;
  border: 1px solid #ffcd7c7d;
  flex-direction: column;
}
.nav-link-selected {
  background-color: #f5efdf;
  color: black;
}
.nav-bar {
  margin: 0;
  padding: 0;
}
.category-count-text {
  display: flex;
  align-items: center;
  justify-content: center;
}
.nav-bar::-webkit-scrollbar {
  width: 10px;
  background: transparent;
}

/* Track */
.nav-bar::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
.nav-bar::-webkit-scrollbar-thumb {
  background: #f5efdf;
  border-radius: 10px;
}

/* Handle on hover */
.nav-bar::-webkit-scrollbar-thumb:hover {
  background: #b1aa98;
}

@media only screen and (max-width: 1100px) {
  .nav-link {
    display: inline-flex;
    text-align: center;
    padding: 14px;
    text-decoration: none;
    margin-left: 10px;
    min-height: 100px;
    max-height: 100px;
    white-space: normal;
    justify-content: center;
    align-items: center;
  }
}
