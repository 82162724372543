.menu {
  display: grid;
  grid-auto-columns: 1fr;
  grid-auto-rows: 1fr;
  grid-template-columns: repeat(auto-fill, minmax(270px, 1fr));
  grid-template-rows: auto;
  gap: 20px;
  color: whitesmoke;
  margin: 0 2rem;
  white-space: nowrap;
}
.product {
  padding: 20px;
  border: #ffcd7c7d 2px solid;
  display: grid;
  gap: 0px 0px;
  align-items: center;
  min-width: 250px;
  border-radius: 10px;
  min-height: 225px;
  cursor: pointer;
  height: 100%;
}
.product-with-add-btn {
  display: flex;
  max-width: 270px;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.product-name {
  width: calc(100% - 10px);
  justify-self: center;
  align-self: center;
  grid-area: 2 / 1 / 3 / 2;
  font-weight: 700;
  margin-bottom: 20px;
  font-size: 1.3rem;
  line-height: 1;
  overflow: hidden;
}
.product-description {
  text-align: center;
  justify-self: center;
  align-self: end;
  text-overflow: ellipsis;
  white-space: pre;
  overflow: hidden;
  width: calc(100% - 10px);
  margin-bottom: 20px;
  font-size: 20px;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  line-clamp: 2;
  -webkit-box-orient: vertical;
}
.product-img-container {
  height: 150px;
  margin-bottom: 10px;
  justify-self: stretch;
  align-self: stretch;
  grid-area: 1 / 1 / 2 / 2;
}
.product-plus {
  justify-self: end;
  align-self: flex-end;
  margin-right: 10px;
}
.product-img {
  height: 100%;
  object-fit: cover;
  border-radius: 25px;
  min-width: 85%;
  max-width: 95%;
}

.menu-type-title {
  color: #f5efdf;
  text-align: center;
  font-size: 45px;
}
.in-cart {
  background-color: #f6dd99ba;
}

@media only screen and (max-width: 1100px) {
  .menu {
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  }
  .product-img-container {
    height: 130px;
  }
}

@media only screen and (max-width: 690px) {
  .product-with-add-btn {
    margin: 0 auto;
  }
}
